onmessage = function (event) {
    //工作线程接收到主线程的消息
    this.log("from main thread:",event.data);
    //向主线程发送消息
    postMessage("向主线程发送消息"+new Date().getTime());
};

//错误信息
onerror = function (event) {
    this.log(event.message);
}