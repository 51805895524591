<template>
  <el-carousel arrow="nerver" height="calc(56vh - 20px)" :interval="5000">
    <el-carousel-item v-for="(item,index) in myData" :key="index" class="container">
      <div v-if="index===0" class="index">
        <div class="bg-one">
          <div class="desc">
            <el-row>
              <el-col :span="12">
                <div class="desc-item">
                  <div class="desc-pos">
                    <div class="desc-item-title">今日排班人数</div>
                    <div class="desc-item-text">{{item.shiftPersonCount || 0}}人</div>
                  </div>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="desc-item">
                  <div class="desc-pos">
                    <div class="desc-item-title">今日排班车辆</div>
                    <div class="desc-item-text">{{item.shiftCarCount || 0}}辆</div>
                  </div>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="desc-item">
                  <div class="desc-pos">
                    <div class="desc-item-title">今日出勤人数</div>
                    <div class="desc-item-text">{{item.attendancePersonCount || 0}}人</div>
                  </div>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="desc-item">
                  <div class="desc-pos">
                    <div class="desc-item-title">今日出勤车辆</div>
                    <div class="desc-item-text">{{item.attendanceCarCount || 0}}辆</div>
                  </div>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="desc-item">
                  <div class="desc-pos">
                    <div class="desc-item-title">当前在岗人数</div>
                    <div class="desc-item-text">{{item.onDutyPersonCount || 0}}人</div>
                  </div>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="desc-item">
                  <div class="desc-pos">
                    <div class="desc-item-title">当前在线车辆</div>
                    <div class="desc-item-text">{{item.onDutyCarCount || 0}}辆</div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <div v-else class="item">
        <aylmap :value="item" class="map" style="width: 100%; height: calc(56vh - 20px);"></aylmap>
        <div class="desc-map">
          <p class="desc-map-title">{{ item.bidName }}</p>
          <p class="desc-map-text">主管单位：{{ item.bidManageUnit }}</p>
          <p class="desc-map-text">环卫企业：{{ item.bidMaintainUnit }}</p>
        </div>
        <div class="desc-main-item">
          <el-row>
            <el-col :span="4">
              <div class="main-item">
                <div class="main-item-pos">
                  <div class="main-item-title">今日排班人数</div>
                  <div class="main-item-text">{{item.shiftPersonCount || 0}}人</div>
                </div>
              </div>
            </el-col>
            <el-col :span="4">
              <div class="main-item">
                <div class="main-item-pos">
                  <div class="main-item-title">今日出勤人数</div>
                  <div class="main-item-text">{{item.attendancePersonCount || 0}}人</div>
                </div>
              </div>
            </el-col>
            <el-col :span="4">
              <div class="main-item">
                <div class="main-item-pos">
                  <div class="main-item-title">当前在岗人数</div>
                  <div class="main-item-text">{{item.onDutyPersonCount || 0}}人</div>
                </div>
              </div>
            </el-col>
            <el-col :span="4">
              <div class="main-item">
                <div class="main-item-pos">
                  <div class="main-item-title">今日排班车辆</div>
                  <div class="main-item-text">{{item.shiftCarCount || 0}}辆</div>
                </div>
              </div>
            </el-col>

            <el-col :span="4">
              <div class="main-item">
                <div class="main-item-pos">
                  <div class="main-item-title">今日出勤车辆</div>
                  <div class="main-item-text">{{item.attendanceCarCount || 0}}辆</div>
                </div>
              </div>
            </el-col>

            <el-col :span="4">
              <div class="main-item">
                <div class="main-item-pos">
                  <div class="main-item-title">当前在线车辆</div>
                  <div class="main-item-text">{{item.onDutyCarCount || 0}}辆</div>
                </div>
              </div>
            </el-col>
          </el-row>
          <!-- <ul>
            <li>今日排班人数:{{item.shiftPersonCount}}</li>
            <li>今日排班车辆:{{item.shiftCarCount}}</li>
            <li>今日出勤人数:{{item.attendancePersonCount}}</li>
            <li>今日出勤车辆:{{item.attendanceCarCount}}</li>
            <li>当前在岗人数:{{item.onDutyPersonCount}}</li>
            <li>当前在线车辆:{{item.onDutyCarCount}}</li>
          </ul>-->
        </div>
      </div>
    </el-carousel-item>
  </el-carousel>
</template>
<script>
import mixin from "./mixin-all";
import aylmap from "./aylmap";
import Thread from "./thread.worker.js";

export default {
  mixins: [mixin],
  components: { aylmap },
  data() {
    return {
      //↓/////钩子字段//////////////
      name: "jrcqsj",
      cnName: "今日出勤数据",
      api: "/sanitation/dataCloudService/getStatisticOnDuty",
      isMock: false, //是否mock模拟，若是，不请求远程API，直接使用comData的假数据
      comData: null,
      //↑////钩子字段//////////////
      debug: 0,
      thread: null,
    };
  },
  computed: {
    myData() {
      let self = this;
      return self.comData && Array.isArray(self.comData) && self.comData.length
        ? [
            self.comData.reduce((sum, item) => ({
              bidMaintainUnit: item.bidMaintainUnit,
              bidManageUnit: item.bidManageUnit,
              attendanceCarCount:
                sum.attendanceCarCount + item.attendanceCarCount,
              attendancePersonCount:
                sum.attendancePersonCount + item.attendancePersonCount,
              onDutyCarCount: sum.onDutyCarCount + item.onDutyCarCount,
              onDutyPersonCount: sum.onDutyPersonCount + item.onDutyPersonCount,
              shiftCarCount: sum.shiftCarCount + item.shiftCarCount,
              shiftPersonCount: sum.shiftPersonCount + item.shiftPersonCount,
            })),
            ...self.comData,
          ]
        : self.comData;
    },
  },
  mounted() {
    let self = this;
    if (self.isMock) {
      self.comData = getMockData();
      let timer = setInterval(async () => {
        self.comData = getMockData();
      }, 6000);
      self.$once("hook:beforeDestory", () => {
        clearInterval(timer);
        timer = null;
      });
    }

    // if (self.thread == null) {
    //   self.thread = new Thread();
    //   self.thread.postMessage([5,2]);
    //   self.log("begin postMessage……");
    //   self.thread.onmessage = function(event){
    //     self.log("from thread.worker.js:"+event.data);
    //   }
    // }
  },
  beforeDestroy(){
    let self = this;
    if(self.thread){
      self.thread.terminate();
      self.thread = null;
    }
  }
};

var arr = [
  [112.923655, 28.185635],
  [112.923878, 28.191379],
  [112.933136, 28.193991],
  [112.940542, 28.197711],
  [112.948689, 28.198299],
  [112.946319, 28.190792],
  [112.945356, 28.184198],
  [112.941579, 28.180738],
  [112.940691, 28.170619],
  [112.933876, 28.168856],
  [112.926618, 28.176952],
];
// function random() {
//   return arr[Number(Math.random() * 10).toFixed(0)];
// }
//获取Mock数据，也即假数据，以便前端可以独立调试API
function getMockData() {
  return [
    {
      bidId: "a01",
      bidMaintainUnit: "养护企业",
      bidManageUnit: "艾依灵科技",
      bidName: "长沙中南大学标段",
      bidScope: arr,
      carList: [
        {
          coordinate: arr[Number(Math.random() * 10).toFixed(0)],
          deviceId: "string",
          status: 0,
        },
        {
          coordinate: arr[Number(Math.random() * 10).toFixed(0)],
          deviceId: "string",
          status: 1,
        },
        {
          coordinate: arr[Number(Math.random() * 10).toFixed(0)],
          deviceId: "string",
          status: 2,
        },
        {
          coordinate: arr[Number(Math.random() * 10).toFixed(0)],
          deviceId: "string",
          status: 3,
        },
      ],
      personList: [
        {
          coordinate: arr[Number(Math.random() * 10).toFixed(0)],
          personId: "string",
          status: 0,
        },
        {
          coordinate: arr[Number(Math.random() * 10).toFixed(0)],
          personId: "string",
          status: 1,
        },
        {
          coordinate: arr[Number(Math.random() * 10).toFixed(0)],
          personId: "string",
          status: 2,
        },
      ],
      attendanceCarCount: 22, //今日出勤车辆
      attendancePersonCount: 26, //今日出勤人数
      onDutyCarCount: 10,
      onDutyPersonCount: 12,
      shiftCarCount: 11,
      shiftPersonCount: 23,
    },
    // {
    //   bidId: "a02",
    //   bidMaintainUnit: "养护企业",
    //   bidManageUnit: "艾依灵科技",
    //   bidName: "长沙湖南师范大学标段",
    //   bidScope: [
    //     [112.923655, 28.185635],
    //     [112.923878, 28.191379],
    //     [112.933136, 28.193991],
    //     [112.940542, 28.197711],
    //     [112.948689, 28.198299],
    //     [112.946319, 28.190792],
    //     [112.945356, 28.184198],
    //     [112.941579, 28.180738],
    //     [112.940691, 28.170619],
    //     [112.933876, 28.168856],
    //     [112.926618, 28.176952]
    //   ],
    //   carList: [
    //     {
    //       coordinate: [112.923655, 28.185635],
    //       deviceId: "string",
    //       status: 0
    //     },
    //     {
    //       coordinate: [112.941579, 28.180738],
    //       deviceId: "string",
    //       status: 1
    //     },
    //     {
    //       coordinate: [112.940691, 28.170619],
    //       deviceId: "string",
    //       status: 2
    //     },
    //     {
    //       coordinate: [112.933876, 28.168856],
    //       deviceId: "string",
    //       status: 3
    //     }
    //   ],
    //   personList: [
    //     {
    //       coordinate: [112.945356, 28.184198],
    //       personId: "string",
    //       status: 1
    //     }
    //   ],
    //   attendanceCarCount: 22, //今日出勤车辆
    //   attendancePersonCount: 26, //今日出勤人数
    //   onDutyCarCount: 10,
    //   onDutyPersonCount: 12,
    //   shiftCarCount: 11,
    //   shiftPersonCount: 23
    // },
  ];
}
</script>
<style scoped>
/deep/ .el-carousel__button {
  background-color: #00fcff;
  width: 5px;
  height: 5px;
  border-radius: 50%;
}
.container {
  width: 100%;
  height: calc(56vh - 20px);
  /* border: 1px solid #0092fe; */
}
.index {
  height: calc(56vh - 20px);
  width: calc(100% - 2px);
  /* border: 1px solid #0092fe; */
  /* margin-left: -2px;
  margin-right: -2px; */
}
.item {
  width: 100%;
  height: calc(56vh - 20px);
  position: relative;
}
.map {
  position: absolute;
  /* border: 1px solid #0092fe; */
  background: url("../../../assets/images/cloud-hw/banner-kuang.png") no-repeat
    top / cover;
  background-size: 100% 100%;
  padding: 2px;
  /* margin: 8px 2px 0 2px; */
}
.bg-one {
  width: 100%;
  height: calc(56vh - 20px);
  background: url("../../../assets/images/cloud-hw/banner01.png") no-repeat top /
    cover;
}
.desc {
  width: 100%;
  height: calc(56vh - 20px);
  position: absolute;
  padding: 7vh 10vh;
}
.desc-item {
  width: calc(100% - 40px);
  height: calc((36vh - 20px) / 3);
  background: url("../../../assets/images/cloud-hw/banner-anniu.png") no-repeat
    top / cover;
  background-size: 100% 100%;
  margin: 10px;
  font-weight: bold;
  color: rgba(0, 252, 255, 1);
  text-align: center;
  position: relative;
}
.desc-pos {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.desc-item-title {
  line-height: 28px;
  font-size: 1.5em;
  font-weight: 500;
}
.desc-item-text {
  font-size: 2.25em;
}
.desc-map {
  position: absolute;
  top: 10px;
  left: 20px;
  font-weight: 400;
  color: rgba(0, 252, 255, 1);
}
.desc-map-title {
  font-size: 1.25em;
  line-height: 28px;
}
.desc-map-text {
  font-size: 1em;
  line-height: 28px;
}
.desc-main-item {
  width: 95%;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
}
.main-item {
  width: calc(100% - 10px);
  height: 8vh;
  background: url("../../../assets/images/cloud-hw/banner02-anniu.png")
    no-repeat top / cover;
  background-size: 100% 100%;
  margin: 0 5px;
  font-size: 1em;
  text-align: center;
  position: relative;
}
.main-item-pos {
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.main-item-title {
  line-height: 24px;
  font-weight: bold;
}
.main-item-text {
  font-weight: bold;
  color: rgba(0, 252, 255, 1);
  font-size: 1.25em;
}
</style>