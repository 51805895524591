import Axios from 'axios'
export default {
    data() {
        return {
            comData: null,
            refreshTimer:20*1000,//大屏数据刷新的时间间隔
            isMock: false,
            timerList:[],
            timer:null,
        }
    },
    methods: {
        // 修改表格样式
        rowStyle({
            row,
            rowIndex
        }) {
            if (rowIndex % 2 !== 0) {
                return {
                    background: "rgba(15,111,217,0.3)",
                    color: "rgba(255,255,255,0.8)",
                    border: "0px"
                };
            } else {
                return {
                    background: "transparent",
                    color: "rgba(255,255,255,0.8)",
                    border: "0px"
                };
            }
        },
        headerRowStyle() {
            return {
                background: "#07317E",
                color: "#00FBFF",
                border: "0px"
            };
        },
        async getData() {
            let self = this;
            if (self.api && !self.isMock)
                self.comData = await Axios.post(self.api, {})
        }
    },
    async mounted() {
        let self = this;
        self.log("//////////////////////////////mounted")
        await self.getData();        
        self.timer = setInterval(async () => {
            await self.getData();
        }, self.refreshTimer);
        self.timerList.push(self.timer);
    },
    beforeDestroy() {
        this.log("//////////////////////////////beforeDestroy")
        this.timerList.forEach((item) => {
            clearInterval(item);
        })
        this.timerList = [];    
        this.timer = null;
    },
    destroyed() {
    }
}