<template>
  <div v-bind="$attrs" v-on="$listeners">
    <!-- <span v-if="debug">////{{getMapData}}/////</span> -->
    <div :id="'map_'+getMapData.bidId" style="width: 100%; height: 100%;"></div>
  </div>
</template>
<script>
import person0 from "@/assets/images/hw-icon/renyuan-lv.png"
import person1 from "@/assets/images/hw-icon/renyuan-huang.png"
import person2 from "@/assets/images/hw-icon/renyuan-hui.png"
import car0 from "@/assets/images/hw-icon/cheliang-lv.png"
import car1 from "@/assets/images/hw-icon/cheliang-huang.png"
import car2 from "@/assets/images/hw-icon/cheliang-hong.png"
import car3 from "@/assets/images/hw-icon/cheliang-hui.png"


export default {
  name: "aylmap",

  inheritAttrs: false,

  props: {
    //地图数据
    value: {
      type: Object
      // default:{
      //    bidScope: null,       //围栏范围，经纬度点阵数组，形如[[118,36],[117,32],[116,113]]
      //    carList:null,
      //    personList:null,
      //    move2center:true,  //是否居中显示
      // }
    },
    strokeColor: {
      //多边形线颜色
      type: String,
      default: "#07E4FA"
    },
    fillColor: {
      //多边形填充色
      type: String,
      default: "#07E4FA"
    },
  },

  data() {
    return {
      debug: 0,
      mapData: this.value,
      map: null, //高德插件
      polygon: null, //高德多边形,
      icons:{person:[person0,person1,person2],car:[car0,car1,car2,car3]},
      markerList:[]
    };
  },

  methods: {
    initAmap() {
      let self = this;
      // eslint-disable-next-line no-undef
      if (!self.map) {
        self.map = new AMap.Map("map_" + self.getMapData.bidId, {
          resizeEnable: true,
          dragEnable: false,
          zoomEnable: false,
          doubleClickZoom: false,
          keyboardEnable: false,
          scrollWheel: false,
          touchZoom: false,
          viewMode: "2D",
          zoom: 11
        });
        self.map.setMapStyle("amap://styles/grey");
      }
    },
    renderDom() {
        let self = this;

        //因为self.mapData是个引用类型，所以不用强制赋值。
        //但是似乎不符合vue的规范。有待商榷。
        // self.mapData = v;

        //self.$nextTick(); //因为地图可能不会刷新，所以需要执行$nextTick()

        // self.log("self.mapData",self.mapData);

        //根据经纬度绘制标记点
        if (self.mapData) {
          self.map.remove(self.markerList);
          self.markerList = [];
          self.mapData.personList && self.mapData.personList.forEach(item => {
            let lnglat = item.coordinate;
            if (Array.isArray(lnglat) && lnglat.length >= 2 && item.status!=2) {
              let marker = new AMap.Marker({
                position: new AMap.LngLat(lnglat[0], lnglat[1]),
                icon:self.icons["person"][item.status],
                anchor:"bottom-center",
                offset: new AMap.Pixel(0,0),
              });
              self.map.add(marker);
              self.markerList.push(marker);
            }
          });
          self.mapData.carList && self.mapData.carList.forEach(item => {
            let lnglat = item.coordinate;
            if (Array.isArray(lnglat) && lnglat.length >= 2 && item.status!=3) {
              let marker = new AMap.Marker({
                position: new AMap.LngLat(lnglat[0], lnglat[1]),
                icon:self.icons["car"][item.status],
                anchor:"center",
                offset: new AMap.Pixel(0,0),
              });
              self.map.add(marker);
              self.markerList.push(marker);
            }
          });
        }

        //回显绘制围栏范围（多边形）
        if (self.mapData.bidScope) {
          if(self.polygon==null){
            self.polygon = new AMap.Polygon({
              path: self.getScopeData, //设置多边形边界路径
              strokeColor: self.mapData.strokeColor || self.strokeColor, //线颜色
              strokeWeight: 2, //线宽
              strokeOpacity: 1, //线透明度
              fillOpacity: 0.2, //填充透明度
              fillColor: self.mapData.fillColor || self.fillColor, //填充色
              bubble: true,
              zIndex: 1
            });
            self.map.add(self.polygon);
          }else{
            self.polygon.setPath(self.getScopeData);
          }
        }

        // console.time("aylmap/renderDom");
        // self.log("aylmap/carList",JSON.stringify(self.mapData.carList));
        // self.log("aylmap/personList",JSON.stringify(self.mapData.personList));

        // 缩放地图到合适的视野级别
        self.mapData.bidScope &&
          self.polygon &&
          self.map.setFitView([self.polygon],true,[10,110,0,0]);
      }
  },

  watch: {
    value: {
      deep: true,
      handler: function(v,ov){
        this.log("aylmap/watch/carlist", JSON.stringify(v.carList));
        this.mapData = v;
        return this.renderDom()
      },
    }
  },

  computed: {
    getMapData: {
      get() {
        return this.mapData;
      }
    },
    getScopeData: {
      get() {
        return this.$_.cloneDeep(this.mapData.bidScope);
      }
    }
  },

  mounted() {
    let self = this;
    self.initAmap();
    self.renderDom();
  }
};
</script>
<style scoped>
</style>